<template>
  <div class="container" v-title :data-title="$t('i18n.faultHistory')">
    <div id="outer-title">{{ $t("i18n.faultHistory") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @init="init"
        :options="options"
        :operates="operates"
        :columns="columns"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.picture')"
      v-model="dialogFormVisible"
      width="50%"
      :before-close="handleClose"
      destroy-on-close
    >
    <el-image :src="imgSrc"></el-image>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
export default {
  name: "EleHistoryFault",
  components: {
    jlTable,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      dialogFormVisible: false,
      imgSrc: "",
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: false, // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "number",
          label: "elevatorNo",
          align: "center",
        },
        {
          prop: "faultCode",
          label: "faultCode",
          align: "center",
        },
        {
          prop: "faultTime",
          label: "faultTime",
          align: "center",
          type: "dateRange",
          search: true,
        },
        {
          prop: "levels",
          label: "faultLevel",
          align: "center",
          type: "select",
          props: { label: "label", value: "value" },
          data: [
            { label: t("i18n.firstFaultLevel"), value: "firstFaultLevel" },
            { label: t("i18n.secondFaultLevel"), value: "secondFaultLevel" },
            { label: t("i18n.thirdFaultLevel"), value: "thirdFaultLevel" },
          ],
          search: true,
          formatter: (row) => {
            if (row.levels) {
              return `<span>${t("i18n." + row.levels)}</span>`;
            } else {
              return "";
            }
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 70,
        fixed: "right",
        list: [
          {
            id: "1",
            label: "picture",
            icon: "el-icon-view",
            show: true,
            plain: false,
            disabled: false,
            type: "inner",
            method: (index, row) => {
              state.dialogFormVisible = true;
              state.imgSrc = proxy.$defined.imgUrl + "camera/" + row.number + "/" + row.alias +".jpg";
            },
          },
        ],
      },
      total: 0,
      loading: true,
    });

    const init = async (params) => {
      state.loading = true;
      let number = route.query.enumber;
      let p = setData(params);
      const { data } = await proxy.$api.monitor.getHistoryFault(number, p);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const setData = (data) => {
      let obj = {};
      for (let key in data) {
        if (key === "faultTime" && data[key]) {
          obj.beginTime = proxy.$defined.format(data[key][0]) + " 00:00:00";
          obj.endTIme = proxy.$defined.format(data[key][1]) + " 23:59:59";
        } else {
          obj[key] = data[key];
        }
      }
      return obj;
    };

    return {
      ...toRefs(state),
      init,
    };
  },
};
</script>

<style lang="scss">
@media screen and (max-width: 768px) {
}
</style>
